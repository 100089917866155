import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient } from '@angular/common/http';
import { from, Observable, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Usuario } from '../models/usuario.model';
import { catchError } from 'rxjs/operators';
import { AbstractService } from './abstract.service';


export const TOKEN_AUTH = '_iam_tk_auth';
export const br3_USER = '_user_iam';
export const br3_LAST_USER = '_last_user_iam';

export class Menu {
  title: string;
  functionality?: string;
  url?: string;
  icon?: string;
  onlyAdmin: boolean;
  simpleUser?: boolean;
  children?: Menu[];
}

@Injectable({
  providedIn: 'root'
})
export class AuthService extends AbstractService<Usuario>{

  jwtHelper = new JwtHelperService();
  $user: Subject<any> = new Subject<any>();

  constructor(public http: HttpClient) {
    super('auth', http);
  }

  isAuthenticated() {
    const token = this.getToken();

    if (!token) {
      return false;
    }

    return !this.jwtHelper.isTokenExpired(token);
  }

  login(email: string, password: string): Observable<any> {
    return from(new Promise((resolve, reject) => {
      this.post({email, password}, `login/profissional`)
      .subscribe(async (data: Usuario) => {
        if (data) {
          this.setToken(data.access_token);
          this.setUser(data);
          resolve(data);
          return;
        }
        reject();
      }, reject);
    }));
  }

  alterarPhoto(user: Usuario) {
    return this.post(user, `photo/${user._id}`);
  }

  atualizarSenha(user: Usuario) {
    return this.put(user, `change-password`);
  }

  enviarCodigo(email: string) {
    return this.post({email}, `code-recovery`);
  }

  resetarSenha(user: { codigoRecuperacao: string; password: string; email: string }) {
    return this.post(user, `reset-password`);
  }

  cadastrar(usuario: Usuario) {
    return this.post(usuario, 'profissional');
  }

  setUser(user: Usuario) {
    delete user.password;
    this.$user.next(user);
    localStorage.setItem(br3_USER, JSON.stringify(user));
    localStorage.setItem(br3_LAST_USER, user.email);
  }

  getUser(): Usuario {
    return JSON.parse(localStorage.getItem(br3_USER));
  }

  setToken(token: string) {
    return localStorage.setItem(TOKEN_AUTH, token);
  }

  getToken(): string {
    return localStorage.getItem(TOKEN_AUTH);
  }

  public nextUser() {
    this.$user.next(this.getUser());
  }

  clearAuthorizationToken(): void {
    this.$user.next({});
    localStorage.removeItem(TOKEN_AUTH);
    localStorage.removeItem(br3_USER);
    localStorage.removeItem(TOKEN_AUTH);
  }

}
