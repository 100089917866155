import { Component, OnInit } from '@angular/core';
import { AlertController, Platform } from '@ionic/angular';
import { Plugins } from '@capacitor/core';
import { SwUpdate } from '@angular/service-worker';
import { AuthService } from './shared/services/auth.service';
import { NavigationEnd, Router } from '@angular/router';
import { EventsService } from './shared/services/events.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

const { SplashScreen, StatusBar } = Plugins;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})

export class AppComponent implements OnInit {

  appPages = [
    {
      id: 1,
      title: 'MENU.AGENDA',
      url: '/agenda',
      icon: 'calendar'
    },
    {
      id: 2,
      title: 'MENU.PACIENTES',
      url: '/pacientes',
      icon: 'users'
    },
    {
      id: 3,
      title: 'MENU.LINHAS_CUIDADO',
      url: '/linhas-cuidado',
      icon: 'file-text'
    },
    {
      id: 4,
      title: 'MENU.ACOMPANHAMENTO',
      url: '/acompanhamento',
      icon: 'medical-monitor'
    }
  ];

  isBorderRadius = false;

  constructor(
    private router: Router,
    private platform: Platform,
    private swUpdate: SwUpdate,
    private event: EventsService,
    private authService: AuthService,
    private translate: TranslateService,
    private alertCtrl: AlertController
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.obeveRoutes();

      const lang = !localStorage.getItem('lang') || localStorage.getItem('lang') === 'pt' ?
        'pt-br' : localStorage.getItem('lang');
      moment.locale(lang);
      this.checkForUpdate();
      if (this.platform.is('hybrid')) {
        StatusBar.setBackgroundColor({
          color: '#FFF'
        });
        setTimeout(() => {
          SplashScreen.hide();
        }, 2000);
      }
    });
  }

  /**
   * Observa trocas de rota e configura radius no routerOutlet
   */
  obeveRoutes() {
    this.router.events.subscribe((ev: any) => {
      if (ev instanceof NavigationEnd) {
        if (
          ev.url &&
          ev.url !== '/login' &&
          ev.url !== '/registro' &&
          ev.url !== '/recuperar-senha'
        ) {
          this.isBorderRadius = true;
        } else {
          this.isBorderRadius = false;
        }
      }
    });
  }

  checkForUpdate() {
    // tslint:disable-next-line:no-console
    console.info('check for update sem refresh');
    this.swUpdate.available.subscribe(event => {
      this.confirmUpdateApp();
      // tslint:disable-next-line:no-console
      console.info('current version is ', event.current, ' available version is ', event.available);
    });
    this.swUpdate.activated.subscribe(event => {
      // tslint:disable-next-line:no-console
      console.info('old version was ', event.current, ' new version is ', event.previous);
    });
  }

  async confirmUpdateApp() {
    const alert = await this.alertCtrl.create({
      header: this.translate.instant('ATUALIZACAO_DISPONIVEL_TITULO'),
      message: this.translate.instant('ATUALIZACAO_DISPONIVEL'),
      cssClass: 'alert-custom',
      buttons: [
        {
          text: this.translate.instant('NAO'),
          cssClass: 'half default',
          role: 'cancel',
        }, {
          text: this.translate.instant('SIM'),
          cssClass: 'half primary',
          handler: () => {
            window.location.reload();
          }
        }
      ]
    });
    await alert.present();
  }

  ngOnInit() {
  }

  goHome() {
    this.router.navigate(['/agenda'], { replaceUrl: true });
  }

  async logout() {
    const alert = await this.alertCtrl.create({
      header: this.translate.instant('ENCERRAR_SESSAO'),
      message: this.translate.instant('ENCERRAR_SESSAO_PERGUNTA'),
      mode: 'md',
      cssClass: 'alert-custom',
      buttons: [
        {
          text: this.translate.instant('FORM.CANCELAR'),
          role: 'cancel',
          cssClass: 'half default',
        }, {
          text: this.translate.instant('FORM.OK'),
          cssClass: 'half primary',
          handler: () => {
            this.authService.clearAuthorizationToken();
            this.router.navigate([ '/login' ]);
          }
        }
      ]
    });

    await alert.present();
  }
}
