// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  env: 'dev',
  appUrl: 'https://hlg.pro.br3.app',
  apiUrl: 'https://hlg.api-health.contrictor.com.br',
  appBr3Url: 'https://hlg.health.contrictor.com.br',
  apiMeet: 'https://meet-health.contrictor.com.br',
  socketUrl: 'https://hlg.socket.contrictor.com.br',
  restcountries: 'https://restcountries.com'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
