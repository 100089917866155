import {Subject} from 'rxjs';

export class EventsService {

  private event = new Subject<{pages:Array<any>, index?:number}>();

  private defaultPages = [
    {
      id: 1,
      title: 'MENU.AGENDA',
      url: '/agenda',
      icon: 'calendar'
    },
    {
      id: 2,
      title: 'MENU.PACIENTES',
      url: '/pacientes',
      icon: 'users'
    }
  ];

  publish(data: any, index=null) {
    this.event.next({ pages: data, index });
  }

  reset(index=null) {
    this.event.next({ pages:this.defaultPages, index })
  }

  getObservable(): Subject<any> {
    return this.event;
  }
}
