import { environment } from '../../../environments/environment';
import * as moment from 'moment';
import { Agendamento } from '../models/agendamento.model';
import { toast } from './toast';
import { isAfter, isEqual, parseISO, startOfDay } from 'date-fns';

export function removerAcentos(newStringComAcento) {
  let str = newStringComAcento.toString();
  const mapaAcentosHex = {
    a: /[\xE0-\xE6]/g,
    e: /[\xE8-\xEB]/g,
    i: /[\xEC-\xEF]/g,
    o: /[\xF2-\xF6]/g,
    u: /[\xF9-\xFC]/g,
    c: /\xE7/g,
    n: /\xF1/g,
  };

  for (const letra in mapaAcentosHex) {
    const expressaoRegular = mapaAcentosHex[letra];
    str = str.replace(expressaoRegular, letra);
  }
  return str;
}

export function getAppUrl() {
  const baseUrl = getCookie('baseUrl');
  return baseUrl ? baseUrl : environment.apiUrl;
}

export function getCookie(cname) {
  const name = cname + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    // tslint:disable-next-line:triple-equals
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    // tslint:disable-next-line:triple-equals
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export function getDataLabelsFromArray(data: any[], labelKey: string) {
  const labels = [];
  data.forEach((d) => {
    if (d[labelKey]) {
      labels.push(moment(d[labelKey]).utcOffset('America/Sao_Paulo').format('DD/MM/YYYY'));
    } else {
      console.warn(`Key ${labelKey} not found in array`);
    }
  });
  return labels;
}

export function getDataFromArray(data: any[], labelKey: string) {
  const res = [];
  data.forEach((d) => {
    if (d[labelKey]) {
      res.push(d[labelKey]);
    } else {
      console.warn(`Key ${labelKey} not found in array`);
    }
  });
  return res;
}

export function checkIfItsReturning(list: Agendamento[], startingDate?: moment.Moment) {
  const today = startingDate.startOf('day') || moment().startOf('day');
  return list.reduce((accumulator: Agendamento[], currentValue, idx, arr) => {
    const hasPrevious = arr.slice(0, idx).filter((i) => {
      return currentValue && currentValue.paciente && i && i.paciente && i.paciente._id === currentValue.paciente._id;
    });
    // check if has previous Agendamento
    if (hasPrevious.length) {
      const last = hasPrevious[hasPrevious.length - 1];
      // check if it have more than 15 days
      if (moment(last.dataInicio).add(15, 'days') > moment(currentValue.dataInicio)) {
        currentValue.retorno = true;
      }
    }
    // remove unnecessary Agendamentos (the ones that came with the -15 days filter)
    if (moment(currentValue.dataInicio) > today) {
      accumulator.push(currentValue);
    }
    return accumulator;
  }, []);
}


export function calculoIMC(peso, altura) {
  const imc = peso && altura ? (peso / (altura * altura)).toFixed(2) : '-';
  return imc;
}

export function labelIMC(peso, altura) {
  const imc: any = peso && altura ? (peso / (altura * altura)).toFixed(1) : null;
  if (!imc) {
    return '-';
  }

  if (imc < 18.5) {
    return 'ABAIXO_DO_PESO';
  } else if (imc >= 18.5 && imc <= 24.9) {
    return 'PESO_NORMAL';
  } else if (imc >= 25 && imc <= 29.9) {
    return 'SOBREPESO';
  } else if (imc >= 30 && imc <= 34.9) {
    return 'OBESIDADE_GRAU_I';
  } else if (imc >= 35 && imc <= 39.9) {
    return 'OBESIDADE_GRAU_II';
  } else if (imc > 39.9) {
    return 'OBESIDADE_GRAU_III';
  }
}

export function cssIMC(peso, altura) {
  const imc: any = peso && altura ? (peso / (altura * altura)).toFixed(1) : 0;

  if (!imc) {
    return 'green';
  }

  if (imc < 18.5) {
    return 'yellow';
  } else if (imc >= 18.5 && imc <= 24.9) {
    return 'green';
  } else if (imc >= 25 && imc <= 29.9) {
    return 'yellow';
  } else if (imc >= 30 && imc <= 34.9) {
    return 'orange';
  } else if (imc >= 35 && imc <= 39.9) {
    return 'red';
  } else if (imc > 39.9) {
    return 'red';
  }
}

export function isDateEqual(date1: Date, date2?: Date) {
  let compare = startOfDay(new Date());
  if (date2) { compare = startOfDay(date2); }
  // @ts-ignore
  return isAfter(parseISO(date1), compare) || isEqual(parseISO(date1), compare);
}


export function diffDate(date1: any, date2: any) {
  return new Date(date1).getTime() - new Date(date2).getTime();
}

export function calcularIdade(data: any) {
  const hoje = new Date();
  const dtNascimento = new Date(data);
  let diferencaAnos = hoje.getFullYear() - dtNascimento.getFullYear();
  if (new Date(hoje.getFullYear(), hoje.getMonth(), hoje.getDate()) <
    new Date(hoje.getFullYear(), dtNascimento.getMonth(), dtNascimento.getDate())) {
    diferencaAnos--;
  }
  return diferencaAnos;
}

export function copyText(val: string, element: string) {
  const selBox = document.createElement('textarea');
  selBox.style.position = 'fixed';
  selBox.style.left = '0';
  selBox.style.top = '0';
  selBox.style.opacity = '0';
  selBox.value = val.toString();
  document.body.appendChild(selBox);
  selBox.focus();
  selBox.select();
  document.execCommand('copy');
  document.body.removeChild(selBox);
  toast(element + ' copiado para área de transferência');
}
