import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { PreAuthGuard } from './shared/guards/pre-auth.guard';
import { AuthGuard } from './shared/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'agenda',
    pathMatch: 'full'
  },
  {
    path: 'style-guide',
    loadChildren: () => import('./style-guide/style-guide.module').then( m => m.StyleGuidePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule),
    canLoad: [PreAuthGuard]
  },
  {
    path: 'registro',
    loadChildren: () => import('./register/register.module').then( m => m.RegisterModule),
    canLoad: [PreAuthGuard]
  },
  {
    path: 'recuperar-senha',
    loadChildren: () => import('./recovery-password/recovery-password.module').then( m => m.RecoveryPasswordPageModule)
  },
  {
    path: 'agenda',
    loadChildren: () => import('./agenda/agenda.module').then( m => m.AgendaPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'acompanhamento',
    loadChildren: () => import('./acompanhamento/acompanhamento.module').then( m => m.AcompanhamentoPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'pacientes',
    loadChildren: () => import('./pacientes/pacientes.module').then( m => m.PacientesPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'configurar-anamnese',
    loadChildren: () => import('./configurar-anamnese/configurar-anamnese.module').then( m => m.ConfigurarAnamnesePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'configurar-agenda',
    loadChildren: () => import('./configurar-agenda/configurar-agenda.module').then( m => m.ConfigurarAgendaModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'linhas-cuidado',
    loadChildren: () => import('./linhas-cuidado/linhas-cuidado.module').then(m => m.LinhasCuidadoModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'health-forms',
    loadChildren: () => import('./health-forms/health-forms.module').then(m => m.HealthFormsModule),
    canLoad: [AuthGuard]
  },
  { path: '**', redirectTo: 'pacientes' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
